html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#__next {
  height: 100vh;
}

input:-webkit-autofill,
.MuiInputBase-root:has(input:-webkit-autofill) {
  background-color: rgb(232, 240, 254);
}
@media screen and (max-width: 768px) {
  @supports (-webkit-appearance: none) {
    body[style*="overflow: hidden"] {
      touch-action: none;
    }
  }
}

.simplebar-track.simplebar-vertical {
  top: 57px;
  bottom: 44px;
  width: 16px;
}

.simplebar-vertical .simplebar-scrollbar {
  width: 8px;
  margin: 0 4px;
}

.simplebar-scrollbar.simplebar-visible:before {
  background-color: #c4c4c4;
  opacity: 1;
  border-radius: 10px;
}
